import { useEffect } from 'react'

const BackgroundMusic = () => {
  useEffect(() => {
    const audio = new Audio('./audio.mp3');
    audio.loop = false;
    audio.volume = 0.1;
    const playAudio = () => audio.play().catch(console.error);
    document.addEventListener('click', playAudio);
    return () => document.removeEventListener('click', playAudio);
  }, []);

  return null;
}

export default BackgroundMusic
